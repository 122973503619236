export enum ControllerTypes {
  TL7 = "TL7-B2",
  DIM10_087_06 = "DIM10-087-06"
}

export enum PowerDataTypes {
  DIM_10_250 = "DIM-10-250",
  Phillips_DALI = "Phillips DALI",
  STL = "Synapse Twist Lock"
}

export const POWER_DATA_TYPES_FOR_CONTROLLER = new Map([
  [ControllerTypes.TL7, PowerDataTypes.STL],
  [ControllerTypes.DIM10_087_06, PowerDataTypes.Phillips_DALI]
]);

export interface PowerDataModel {
  voltage: number;
  current: number;
  power: number;
}

export enum SiteActions {
  Succeed = "Succeed",
  Fail = "Fail",
  Retry = "Retry",
  Check = "Check"
}

export enum SiteState {
  Provisioning = "Provisioning the Site",
  ProvisioningFailed = "Failed to Provision",
  Provisioned = "Provisioned",
  CheckingStatus = "Checking Status",
  CheckingStatusFailed = "Checking Status Failed"
}

export interface SiteModel {
  id: string;
  name: string;
  mac: string;
  tunnelServerPort?: number;
  version?: string;
  alarms: number;
  controllerType: ControllerTypes;
  fixtures: FixtureModel[];
  userIds: string[];
  state: SiteState;
}

export type SiteCreateParams = Pick<
  SiteModel,
  "name" | "mac" | "controllerType"
>;

export enum FixtureTestActions {
  Start = "Start",
  Succeed = "Succeed",
  Fail = "Fail",
  Retry = "Retry"
}

export enum FixtureTestState {
  ReadyToTest = "Waiting for Test to Start",
  TestingAtZero = "Testing at 0%",
  TestingAtZeroFailed = "Testing at 0% Failed",
  TestingAtFifty = "Testing at 50%",
  TestingAtFiftyFailed = "Testing at 50% Failed",
  TestingAtOneHundred = "Testing at 100%",
  TestingAtOneHundredFailed = "Testing at 100% Failed",
  CheckingPowerLevels = "Checking Power Levels",
  PowerTestFailed = "Power Test Failed",
  Success = "Success"
}

export interface FixtureModel {
  id: string;
  siteId: string;
  pole: string;
  snapaddr: string;
  state: FixtureTestState;
  powerAt0?: PowerDataModel;
  powerAt50?: PowerDataModel;
  powerAt100?: PowerDataModel;
}

export type FixtureCreateParams = Pick<
  FixtureModel,
  "siteId" | "pole" | "snapaddr" | "state"
>;

export type UserModel = import("firebase-admin").auth.UserRecord;

export enum SiteAlarms {
  None = 0b000000,
  NotConnected = 0b000001,
  NeedsUpgrade = 0b000010,
  NeedsTargetTemplate = 0b000100,
  NeedsAPIUser = 0b001000,
  LightsNotInZones = 0b010000,
  NetworkSettingsAreStatic = 0b100000
}
