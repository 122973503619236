import React from "react";
import { FormControl } from "ui/forms";
import "./FormError.scss";

export default function<T>({ control }: { control: FormControl<T> }) {
  return control.showErrors ? (
    <span className="FormError">{control.errors[0]}</span>
  ) : (
    <></>
  );
}
