import React from "react";
import { SiteModel } from "api/models";
import { Icon, Button } from "ui/common";
import { useRouter } from "ui/router";
import "./SiteCard.scss";

export default ({ site }: { site: SiteModel }) => {
  const router = useRouter();

  const onViewSite = () => router.history.push(`/${site.id}`);

  return (
    <div className="SiteCard">
      <div className="icon">
        <Icon size={1.2}>store</Icon>
      </div>
      <h3 className="name">{site.name}</h3>
      <div className="details">
        <h4>
          <em>Port:</em> {site.tunnelServerPort}
        </h4>
        <h4>
          <em>Controller Type:</em> {site.controllerType}
        </h4>
      </div>
      <div className="status">
        <Button onClick={onViewSite} stroked dense color="primary">
          View Site
        </Button>
      </div>
    </div>
  );
};
