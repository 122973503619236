import React, { useState, useEffect, useRef } from "react";
import QRCode from "qrcode-reader";
import "./QRCodeScanner.scss";

export default function QRCodeScanner({
  onRead
}: {
  onRead: (value: string) => void;
}) {
  const [mediaStream, setMediaStream] = useState(null as null | MediaStream);
  const [video, setVideo] = useState(null as null | HTMLVideoElement);
  const onReadRef = useRef(onRead);

  useEffect(() => {
    onReadRef.current = onRead;
  }, [onRead]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: { ideal: "environment" }
        }
      })
      .then(setMediaStream);
  }, []);

  useEffect(() => {
    if (!video || !mediaStream) return;

    const canvas = document.createElement("canvas");
    const reader = new QRCode();

    video.srcObject = mediaStream;
    video.onloadedmetadata = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    };

    const intervalId = setInterval(() => {
      const ctx = canvas.getContext("2d")!;

      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      reader.decode(ctx.getImageData(0, 0, canvas.width, canvas.height));
    }, 100);

    reader.callback = (err: any, value: any) => {
      if (err) return;

      onReadRef.current(value.result);
    };

    return () => {
      mediaStream.getTracks().forEach(track => track.stop());

      clearInterval(intervalId);

      reader.callback = () => {};
    };
  }, [video, mediaStream]);

  return (
    <div className="QRCodeScanner">
      <video ref={setVideo} playsInline autoPlay muted />
      <div className="QRCodeScannerHelper" />
    </div>
  );
}
