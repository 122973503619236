import React from "react";
import { ControllerTypes, SiteCreateParams } from "api/models";
import { useFormControl, useFormGroup } from "ui/forms";
import { InputWrapper, Button, FormError, Select } from "ui/common";
import "./SiteForm.scss";

export default function({
  disabled,
  onSave
}: {
  disabled: boolean;
  onSave: (site: SiteCreateParams) => void;
}) {
  const form = useFormGroup<SiteCreateParams>({
    name: useFormControl("", [
      name => (!name ? "You must provide a name" : true)
    ]),
    mac: useFormControl("", [
      port => (!port ? "You must provide a MAC address" : true)
    ]),
    controllerType: useFormControl(ControllerTypes.TL7 as ControllerTypes)
  });

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (form.valid) {
      onSave(form.value);
    } else {
      form.touch();
    }
  };

  const onNameInput = (event: React.FormEvent<HTMLInputElement>) => {
    form.controls.name.writeValue(event.currentTarget.value);
  };

  const onMacInput = (event: React.FormEvent<HTMLInputElement>) => {
    form.controls.mac.writeValue(event.currentTarget.value);
  };

  return (
    <form onSubmit={onSubmit} className="SiteForm">
      <div className="formField">
        <InputWrapper>
          <input
            type="string"
            placeholder="Name"
            defaultValue={form.controls.name.value}
            onInput={onNameInput}
            onBlur={form.controls.name.touch}
            disabled={disabled}
          />
        </InputWrapper>
        <FormError control={form.controls.name} />
      </div>
      <div className="formField">
        <InputWrapper>
          <input
            type="string"
            placeholder="MAC Address"
            defaultValue={form.controls.mac.value as any}
            onInput={onMacInput}
            onBlur={form.controls.mac.touch}
            disabled={disabled}
          />
        </InputWrapper>
        <FormError control={form.controls.mac} />
      </div>

      <div className="formField">
        <Select
          label="Select Controller Type"
          value={form.controls.controllerType.value}
          options={[
            { label: ControllerTypes.TL7, value: ControllerTypes.TL7 },
            {
              label: ControllerTypes.DIM10_087_06,
              value: ControllerTypes.DIM10_087_06
            }
          ]}
          onChange={value => form.controls.controllerType.writeValue(value)}
          disabled={disabled}
        />
      </div>

      <Button type="submit" disabled={disabled}>
        Create Site
      </Button>
    </form>
  );
}
