import React, { useState } from "react";
import { TitleBar, ContentArea } from "ui/common";
import { useRouter } from "ui/router";
import SiteForm from "./SiteForm";
import { SiteCreateParams } from "api/models";
import { addSite } from "ui/functions";

export default () => {
  const [saving, setIsSaving] = useState(false);
  const router = useRouter();

  const onSave = async (site: SiteCreateParams) => {
    try {
      setIsSaving(true);

      await addSite(site);

      router.history.push("/");
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <TitleBar>Create Site</TitleBar>
      <ContentArea>
        {() => <SiteForm disabled={saving} onSave={onSave} />}
      </ContentArea>
    </>
  );
};
