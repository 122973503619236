import firebase from "firebase/app";
import "firebase/functions";
import { SiteCreateParams, UserModel } from "api/models";

export const scheduleCensusForSite: (args: {
  siteId: string;
}) => Promise<{
  data: { data: { status: "ok" } }
}> = arg => firebase.functions().httpsCallable("scheduleCensusForSite")(arg);

export const addFixtureToSite: (args: {
  siteId: string;
  snapaddr: string;
  pole: string;
}) => Promise<{
  data: { fixtureId: string };
}> = arg => firebase.functions().httpsCallable("addFixtureToSite")(arg);

export const retryTestingFixture: (args: {
  siteId: string;
  fixtureId: string;
}) => Promise<{
  data: { status: "ok" };
}> = arg => firebase.functions().httpsCallable("retryTestingFixture")(arg);

export const startTestingSite: (args: {
  siteId: string;
}) => Promise<{
  data: { status: "ok" };
}> = arg => firebase.functions().httpsCallable("startTestingSite")(arg);

export const deleteFixtureFromSite: (args: {
  siteId: string;
  fixtureId: string;
}) => Promise<{
  data: { status: "ok" };
}> = arg => firebase.functions().httpsCallable("deleteFixtureFromSite")(arg);

export const loginSuccess: () => Promise<{
  data: { status: "ok" };
}> = () => firebase.functions().httpsCallable("loginSuccess")();

export const addSite: (
  args: SiteCreateParams
) => Promise<{
  data: { siteId: string };
}> = args => firebase.functions().httpsCallable("addSite")(args);

export const retryProvisioningSite: (args: {
  siteId: string;
}) => Promise<{
  data: { status: "ok" };
}> = args => firebase.functions().httpsCallable("retryProvisioningSite")(args);

export const getAllUsers: () => Promise<{
  data: UserModel[];
}> = () => firebase.functions().httpsCallable("getAllUsers")();

export const addUserToSite: (args: {
  userId: string;
  siteId: string;
}) => Promise<{ data: { status: "ok" } }> = args =>
  firebase.functions().httpsCallable("addUserToSite")(args);

export const removeUserFromSite: (args: {
  userId: string;
  siteId: string;
}) => Promise<{
  data: { status: "ok " };
}> = args => firebase.functions().httpsCallable("removeUserFromSite")(args);
