import React from "react";
import { FixtureModel } from "api/models";
import FixturePreview from "./FixturePreview";
import ListLabel from "./ListLabel";
import "./FixturePreviewList.scss";

export default function FixturePreviewList(props: {
  label: string;
  fixtures: FixtureModel[];
  onSelect: (fixture: FixtureModel) => void;
}) {
  if (props.fixtures.length === 0) return <></>;

  return (
    <>
      <ListLabel>{props.label}</ListLabel>
      <div className="FixturePreviewList">
        {props.fixtures.map(fixture => (
          <FixturePreview
            fixture={fixture}
            key={fixture.id}
            onSelect={props.onSelect}
          />
        ))}
      </div>
    </>
  );
}
