import React from "react";
import "./Button.scss";

export default function Button({
  flat = false,
  stroked = false,
  dense = false,
  color = "accent",
  children,
  ...buttonProps
}: {
  flat?: boolean;
  stroked?: boolean;
  dense?: boolean;
  color?: "primary" | "accent" | "default" | "warn";
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const raised = !flat && !stroked;
  return (
    <div
      className={`Button ${raised ? "raised" : ""} ${flat ? "flat" : ""} ${
        stroked ? "stroked" : ""
      } ${dense ? "dense" : ""} ${color}`}
    >
      <button type="button" {...buttonProps}>
        {children}
      </button>
    </div>
  );
}
