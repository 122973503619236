import React, { useState } from "react";
import { SiteModel, SiteState } from "api/models";
import { PageSpinner, TitleBar, Icon } from "ui/common";
import { useRouter } from "ui/router";
import { retryProvisioningSite } from "ui/functions";
import "./SiteResults.scss";

export default function SiteResults({ site }: { site: SiteModel }) {
  const router = useRouter();

  const onClose = () => router.history.push(`/`);

  if (!site) return <PageSpinner>Loading Site...</PageSpinner>;

  return (
    <div className="SiteResults">
      <TitleBar canLeave={true} color="default" onLeave={onClose}>
        Site {site.name}
      </TitleBar>
      <SiteResult site={site} onClose={onClose} />
    </div>
  );
}

function SiteResult(props: { site: SiteModel; onClose: () => void }) {
  switch (props.site.state) {
    case SiteState.Provisioning:
      return <PageSpinner>Provisioning site controller</PageSpinner>;

    case SiteState.ProvisioningFailed:
      return (
        <ErrorResultScreen
          title="Unable to communicate with the site controller. Verify it is online and try again."
          site={props.site}
          onClose={props.onClose}
        />
      );

    case SiteState.Provisioned:
      return (
        <ResultScreen
          color="primary"
          icon="check_circle"
          title="This site controller is provisioned"
        />
      );

    default: {
      return <PageSpinner>Looking for a Gateway</PageSpinner>;
    }
  }
}

function ResultScreen({
  icon,
  title,
  children,
  color = "default"
}: {
  icon: string;
  title: string;
  color?: "default" | "primary" | "warn";
  children?: any;
}) {
  return (
    <div className={`ResultScreen ${color}`}>
      <Icon size={3}>{icon}</Icon>
      <h3>{title}</h3>
      {children}
    </div>
  );
}

function ErrorResultScreen({
  title,
  site,
  onClose
}: {
  title: string;
  site: SiteModel;
  onClose: () => void;
}) {
  const [isPending, setIsPending] = useState(false);

  function retry() {
    setIsPending(true);

    retryProvisioningSite({
      siteId: site.id
    })
      .then(() => {
        setIsPending(false);
      })
      .catch(() => {
        setIsPending(false);

        alert("Cannot communicate with the site controller");
      });
  }

  return (
    <ResultScreen color="warn" icon="error_outline" title={title}>
      <div className="ErrorResultsScreenButtons">
        <button disabled={isPending} type="button" onClick={retry}>
          Retry Testing Site
        </button>
      </div>
    </ResultScreen>
  );
}
