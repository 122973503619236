import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { FixtureModel, FixtureTestState } from "api/models";
import { PageSpinner, TitleBar, ContentArea, Fab } from "ui/common";
import { useCurrentSite } from "ui/SiteGuard";
import EmptyState from "./EmptyState";
import FixturePreviewList from "./FixturePreviewList";
import ScheduleCensus from "./ScheduleCensus";
import TestCard from "./TestCard";
import "./FixturesPanel.scss";
import { useFixtures } from "ui/firestore";

// 123615

export default function FixturesPanel(props: RouteComponentProps) {
  const site = useCurrentSite();
  const fixtures = useFixtures(site);

  const onAddFixture = () => props.history.push(`/${site.id}/create-fixture`);
  const onSelectFixture = (id: string) =>
    props.history.push(`/${site.id}/results/${id}`);

  const { failing, ready, pending, succeeding } = useMemo(
    () => deriveFixtureGroups(fixtures || []),
    [fixtures]
  );

  const canStartTest = useMemo(() => failing.length + ready.length > 0, [
    failing,
    ready
  ]);
  const hasFixtures = useMemo(() => Boolean(fixtures && fixtures.length), [
    fixtures
  ]);

  if (!fixtures) return <PageSpinner>Loading fixtures...</PageSpinner>;

  return (
    <>
      <TitleBar>
        <img src="/s-mark.svg" className="Logo" alt="SimplySNAP" />
        {site.name}
      </TitleBar>
      <ContentArea>
        {() => (
          <>
            <ScheduleCensus />
            {canStartTest ? <TestCard /> : <></>}
            {hasFixtures ? (
              <Fab icon="add" onClick={onAddFixture}>
                Add Light Fixture
              </Fab>
            ) : (
              <EmptyState onAddFixture={onAddFixture} />
            )}
            <FixturePreviewList
              label="Failures"
              fixtures={failing}
              onSelect={fixture => onSelectFixture(fixture.id)}
            />
            <FixturePreviewList
              label="Ready to Test"
              fixtures={ready}
              onSelect={fixture => onSelectFixture(fixture.id)}
            />
            <FixturePreviewList
              label="Testing"
              fixtures={pending}
              onSelect={fixture => onSelectFixture(fixture.id)}
            />
            <FixturePreviewList
              label="Succeeded"
              fixtures={succeeding}
              onSelect={fixture => onSelectFixture(fixture.id)}
            />
            <div className="FabSpacer" />
          </>
        )}
      </ContentArea>
    </>
  );
}

function deriveFixtureGroups(fixtures: FixtureModel[]) {
  const ready: FixtureModel[] = [];
  const pending: FixtureModel[] = [];
  const failing: FixtureModel[] = [];
  const succeeding: FixtureModel[] = [];

  for (let fixture of fixtures) {
    switch (fixture.state) {
      case FixtureTestState.Success:
        succeeding.push(fixture);
        break;

      case FixtureTestState.TestingAtZero:
      case FixtureTestState.TestingAtFifty:
      case FixtureTestState.TestingAtOneHundred:
      case FixtureTestState.CheckingPowerLevels:
        pending.push(fixture);
        break;

      case FixtureTestState.TestingAtZeroFailed:
      case FixtureTestState.TestingAtFiftyFailed:
      case FixtureTestState.TestingAtOneHundredFailed:
      case FixtureTestState.PowerTestFailed:
        failing.push(fixture);
        break;

      default:
        ready.push(fixture);
        break;
    }
  }

  return { ready, pending, failing, succeeding };
}
