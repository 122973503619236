import React, { useState, useCallback } from "react";
import "./ContentArea.scss";

export default function ContentArea(props: {
  children: (hasScrolled: boolean) => any;
}) {
  const [hasScrolled, setHasScrolled] = useState(false);

  const listenForScroll = useCallback(
    (element: HTMLDivElement | null) => {
      if (!element) return;

      const listener = () => {
        const nextHasScrolled = element.scrollTop !== 0;

        if (nextHasScrolled !== hasScrolled) setHasScrolled(nextHasScrolled);
      };

      element.addEventListener("scroll", listener);

      return () => {
        element.removeEventListener("scroll", listener);
      };
    },
    [hasScrolled]
  );

  return (
    <div ref={listenForScroll} className="ContentArea">
      {props.children(hasScrolled)}
    </div>
  );
}
