import React from "react";
import "./TitleBar.scss";
import Icon from "./Icon";

export default function({
  children,
  canLeave = false,
  onLeave = () => {},
  color = "primary"
}: {
  children: any;
  canLeave?: boolean;
  onLeave?: () => void;
  color?: "primary" | "default";
}) {
  return (
    <div className={`TitleBar ${color}`}>
      {canLeave && (
        <button className="TitleBarBackButton" onClick={() => onLeave()}>
          <Icon size={1.2}>arrow_back</Icon>
        </button>
      )}
      <h1>{children}</h1>
    </div>
  );
}
