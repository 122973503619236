import React from "react";
import "./ActionCard.scss";

export default function ActionCard(props: {
  title: string;
  action: string;
  onAction: () => void;
  disabled?: boolean;
  children?: any;
}) {
  return (
    <div className="ActionCard">
      <h2>{props.title}</h2>
      <p>{props.children}</p>
      <button type="button" disabled={props.disabled} onClick={props.onAction}>
        {props.action}
      </button>
    </div>
  );
}
