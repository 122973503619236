import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import firebase from "firebase/app";
import "firebase/firestore";
import { FixtureModel, FixtureTestState } from "api/models";
import { useCurrentSite } from "ui/SiteGuard";
import { PageSpinner, TitleBar, Icon } from "ui/common";
import "./FixtureResults.scss";
import { deleteFixtureFromSite, retryTestingFixture } from "ui/functions";

export default function FixtureResults({
  match,
  history
}: RouteComponentProps<{ fixtureId: string }>) {
  const site = useCurrentSite();
  const [fixture, setFixture] = useState(null as null | FixtureModel);

  useEffect(() => {
    return firebase
      .firestore()
      .doc(`/sites/${site.id}/fixtures/${match.params.fixtureId}`)
      .onSnapshot(snap => {
        const fixture = { id: snap.id, ...snap.data() } as any;

        setFixture(fixture);
      });
  }, [site, match.params.fixtureId]);

  const onClose = () => history.push(`/${site.id}`);

  if (!fixture) return <PageSpinner>Loading Fixture...</PageSpinner>;

  return (
    <div className="FixtureResults">
      <TitleBar canLeave={true} color="default" onLeave={onClose}>
        Pole {fixture.pole}
      </TitleBar>
      <FixtureResult fixture={fixture} onClose={onClose} />
    </div>
  );
}

function FixtureResult(props: { fixture: FixtureModel; onClose: () => void }) {
  switch (props.fixture.state) {
    case FixtureTestState.TestingAtZero:
      return <PageSpinner>Dimming Fixture to 0%</PageSpinner>;
    case FixtureTestState.TestingAtFifty:
      return <PageSpinner>Dimming Fixture to 50%</PageSpinner>;
    case FixtureTestState.TestingAtOneHundred:
      return <PageSpinner>Dimming Fixture to 100%</PageSpinner>;
    case FixtureTestState.CheckingPowerLevels:
      return <PageSpinner>Checking power consumption</PageSpinner>;

    case FixtureTestState.ReadyToTest:
      return (
        <ResultScreen
          icon="check_circle"
          title="This lighting fixture is ready to be tested"
        />
      );

    case FixtureTestState.TestingAtZeroFailed:
      return (
        <ErrorResultScreen
          title="Gateway could not dim the fixture to 0%"
          fixture={props.fixture}
          onClose={props.onClose}
        />
      );

    case FixtureTestState.TestingAtFiftyFailed:
      return (
        <ErrorResultScreen
          title="Gateway could not dim the fixture to 50%"
          fixture={props.fixture}
          onClose={props.onClose}
        />
      );

    case FixtureTestState.TestingAtOneHundredFailed:
      return (
        <ErrorResultScreen
          title="Gateway could not dim the fixture to 100%"
          fixture={props.fixture}
          onClose={props.onClose}
        />
      );

    case FixtureTestState.PowerTestFailed:
      return (
        <ErrorResultScreen
          title="The fixture does not appear to be dimming correctly"
          fixture={props.fixture}
          onClose={props.onClose}
        />
      );

    case FixtureTestState.Success:
      return (
        <ResultScreen
          color="primary"
          icon="check_circle"
          title="This lighting fixture is working properly"
        />
      );

    default: {
      return <PageSpinner>Looking for a Gateway</PageSpinner>;
    }
  }
}

function ResultScreen({
  icon,
  title,
  children,
  color = "default"
}: {
  icon: string;
  title: string;
  color?: "default" | "primary" | "warn";
  children?: any;
}) {
  return (
    <div className={`ResultScreen ${color}`}>
      <Icon size={3}>{icon}</Icon>
      <h3>{title}</h3>
      {children}
    </div>
  );
}

function ErrorResultScreen({
  title,
  fixture,
  onClose
}: {
  title: string;
  fixture: FixtureModel;
  onClose: () => void;
}) {
  const [isPending, setIsPending] = useState(false);

  function retry() {
    setIsPending(true);

    retryTestingFixture({
      siteId: fixture.siteId,
      fixtureId: fixture.id
    })
      .then(() => {
        setIsPending(false);
      })
      .catch(() => {
        setIsPending(false);

        alert("Cannot communicate with the site controller");
      });
  }

  function deleteFixture() {
    if (!window.confirm("Are you sure you want to delete this fixture?"))
      return;

    onClose();
    deleteFixtureFromSite({
      siteId: fixture.siteId,
      fixtureId: fixture.id
    });
  }

  return (
    <ResultScreen color="warn" icon="error_outline" title={title}>
      <div className="ErrorResultsScreenButtons">
        <button disabled={isPending} type="button" onClick={retry}>
          Retry Testing Fixture
        </button>
        <button disabled={isPending} type="button" onClick={deleteFixture}>
          Delete Fixture
        </button>
      </div>
    </ResultScreen>
  );
}
