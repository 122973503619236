import React from "react";
import Spinner from "./Spinner";
import "./PageSpinner.scss";

export default function PageSpinner(props: { children: any }) {
  return (
    <div className="PageSpinner">
      <Spinner size={2} />
      <div className="PageSpinnerContent">{props.children}</div>
    </div>
  );
}
