import React, { useMemo } from "react";
import { useUser } from "./AuthGuard";

export function useIsAdmin() {
  const user = useUser();
  const isAdmin = useMemo(() => {
    return user.email && user.email.endsWith("@synapsewireless.com");
  }, [user]);

  return isAdmin;
}

export default function AdminGuard(props: { children: any }) {
  const isAdmin = useIsAdmin();

  if (!isAdmin) return <></>;

  return props.children;
}
