import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "./index.scss";
import App from "./ui/App";
import * as serviceWorker from "./serviceWorker";

firebase.initializeApp({
  apiKey: "AIzaSyBMD7MIwCXjqovTMHBijJ4RoBa9Rc2QSWU",
  authDomain: "bullseye-fa5c7.firebaseapp.com",
  databaseURL: "https://bullseye-fa5c7.firebaseio.com",
  projectId: "bullseye-fa5c7",
  storageBucket: "bullseye-fa5c7.appspot.com",
  messagingSenderId: "481544928454",
  appId: "1:481544928454:web:c257049128e1f114"
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
