import React, { useState, useCallback, useMemo } from "react";
import { Button, Stepper, Step, InputWrapper } from "ui/common";
import { addFixtureToSite } from "ui/functions";
import { useCurrentSite } from "ui/SiteGuard";
import QRCodeScanner from "./QRCodeScanner";
import "./FixtureForm.scss";
import { useFixtures } from "ui/firestore";

export default function FixtureForm(props: { onCreate: () => void }) {
  const site = useCurrentSite();
  const fixtures = useFixtures(site);
  const [pole, setPole] = useState(null as null | string);
  const [snapaddr, setSnapaddr] = useState("");
  const [isSnapAddrTouched, setIsSnapAddrTouched] = useState(false);
  const [pending, setPending] = useState(false);

  const usedSnapAddresses = useMemo(() => {
    if (!fixtures) return [];

    return fixtures.map(fixture => normalizeSNAPAddr(fixture.snapaddr));
  }, [fixtures]);

  const isSnapAddrInvalid = useMemo(() => {
    const addr = normalizeSNAPAddr(snapaddr);

    if (!testSnapAddr(addr)) {
      return `A valid SNAP Address must be a hex value of at least 6 digits with
      optional period and colon delimiters (ex:"a18ca9", "04:3E:82").`;
    }

    if (usedSnapAddresses.some(otherAddr => otherAddr === addr)) {
      return `This SNAP Address is already assigned to a fixture.`;
    }

    return false;
  }, [usedSnapAddresses, snapaddr]);

  const onReadQRCode = useCallback(
    (value: string, ifIsValid: () => void) => {
      const [, snapaddr] = value.split(":");

      setSnapaddr(snapaddr);
      setIsSnapAddrTouched(true);

      const addr = normalizeSNAPAddr(snapaddr);

      if (
        testSnapAddr(addr) &&
        usedSnapAddresses.every(otherAddr => otherAddr !== addr)
      ) {
        ifIsValid();
      }
    },
    [usedSnapAddresses]
  );

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!pole || !snapaddr) return;

    setPending(true);

    addFixtureToSite({
      siteId: site.id,
      snapaddr: normalizeSNAPAddr(snapaddr),
      pole
    })
      .then(() => {
        setPending(false);
        props.onCreate();
      })
      .catch(e => {
        setPending(false);
      });
  }

  return (
    <form className="FixtureForm" onSubmit={onSubmit}>
      <Stepper>
        <Step title="What pole is the fixture on?" step={1}>
          {(isActive, isVisited, step) => {
            if (isActive) {
              return (
                <>
                  <InputWrapper>
                    <input
                      type="string"
                      disabled={pending}
                      placeholder="Pole Number"
                      autoFocus
                      onChange={e => setPole(e.target.value)}
                    />
                  </InputWrapper>
                  <div className="StepActions">
                    <Button
                      color="primary"
                      flat
                      disabled={!pole}
                      onClick={step.continue}
                    >
                      Continue
                    </Button>
                  </div>
                </>
              );
            }

            if (isVisited) {
              return <p>Pole {pole}</p>;
            }

            return <></>;
          }}
        </Step>

        <Step title="Scan the fixture's QR code" step={2}>
          {(isActive, isVisited, step) => {
            if (isActive) {
              return (
                <>
                  <QRCodeScanner
                    onRead={code => onReadQRCode(code, step.continue)}
                  />
                  <span className="alternative">
                    Or enter the fixture's SNAP Address
                  </span>
                  <InputWrapper>
                    <input
                      type="string"
                      disabled={pending}
                      placeholder="AABBCC"
                      value={snapaddr}
                      onChange={e => setSnapaddr(e.target.value)}
                      onBlur={() => setIsSnapAddrTouched(true)}
                    />
                  </InputWrapper>
                  {isSnapAddrInvalid && isSnapAddrTouched ? (
                    <span className="error">{isSnapAddrInvalid}</span>
                  ) : (
                    <></>
                  )}
                  <div className="StepActions">
                    <Button
                      color="primary"
                      flat
                      disabled={Boolean(isSnapAddrInvalid)}
                      onClick={step.continue}
                    >
                      Continue
                    </Button>
                    <Button color="default" flat onClick={step.goBack}>
                      Go Back
                    </Button>
                  </div>
                </>
              );
            }
            if (isVisited) {
              return <p>SNAP Address: {snapaddr}</p>;
            }

            return <></>;
          }}
        </Step>
        <Step title="Save the Fixture" step={3}>
          {(isActive, _, step) =>
            !isActive ? (
              <></>
            ) : (
              <div className="StepActions">
                <Button disabled={pending} type="submit">
                  Save Fixture
                </Button>
                <Button
                  color="default"
                  flat
                  disabled={pending}
                  onClick={step.goBack}
                >
                  Go Back
                </Button>
              </div>
            )
          }
        </Step>
      </Stepper>
    </form>
  );
}

const SNAP_ADDRESS_REGEX = /^(00[.:]?1[cC][.:]?2[cC][.:]?[0-9a-fA-F]{2}[.:]?[0-9a-fA-F]{2}[.:]?)?([0-9a-fA-F][.:]?){5}[0-9a-fA-F]$/;
function normalizeSNAPAddr(addr: string) {
  return addr
    .split(".")
    .join("")
    .split(":")
    .join("")
    .toLowerCase();
}

function testSnapAddr(addr: string) {
  return SNAP_ADDRESS_REGEX.test(addr);
}
