import React from "react";
import Select, { Option } from "@material/react-select";
import "./Select.scss";

export default function<V extends { toString(): string }>({
  label,
  value,
  options,
  disabled,
  onChange
}: {
  label: string;
  value: V;
  options: { label: string; value: V }[];
  disabled?: boolean;
  onChange: (item: V) => void;
}) {
  return (
    <Select
      className="Select"
      enhanced
      label={label}
      value={value.toString()}
      onEnhancedChange={i => onChange(options[i].value)}
      disabled={disabled}
    >
      {options.map((option, index) => (
        <Option key={index} value={option.value.toString()}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
}
