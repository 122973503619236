import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { PageSpinner } from "./common";
import "./AuthGuard.scss";
import { loginSuccess } from "./functions";

const UserContext = createContext<firebase.User>(null as any);

export function useUser(): firebase.User {
  return useContext(UserContext);
}

function SocialButton({
  provider,
  onClick,
  children
}: {
  provider: string;
  onClick: () => void;
  children: string;
}) {
  return (
    <button className={`SocialButton ${provider}`} onClick={onClick}>
      <img src={`/logo-${provider}.svg`} alt={children} />
      {children}
    </button>
  );
}

export default function AuthGuard(props: { children: any }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState(null as null | firebase.User);
  const [hasAuthError, setAuthError] = useState(null as null | Error);

  function createLoginFunction(
    createProvider: () => firebase.auth.AuthProvider
  ) {
    return async () => {
      const provider = createProvider();
      setIsAuthenticating(true);

      try {
        const auth = firebase.auth();

        if (process.env.NODE_ENV === "production") {
          await auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
        }

        const credential = await auth.signInWithPopup(provider);
        setUser(credential.user);
        loginSuccess();
      } catch (e) {
        console.error(e);
        setAuthError(e);
      }
    };
  }

  const loginWithMicrosoft = createLoginFunction(() =>
    new firebase.auth.OAuthProvider("microsoft.com").setCustomParameters({
      tenant: "bb2fface-0670-493a-8c31-4d3487a6dd0d"
    })
  );

  const loginWithGoogle = createLoginFunction(
    () => new firebase.auth.GoogleAuthProvider()
  );

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }

      setIsAuthenticating(false);
    });
  }, []);

  useEffect(() => {
    if (!user) return;

    const eventsToListenFor = [
      "mousemove",
      "mousedown",
      "touchstart",
      "click",
      "scroll",
      "keypress"
    ];
    const logout = () => {
      firebase.auth().signOut();
      setUser(null);
    };

    const timer = new Worker("/timer.js");
    const resetTimer = () => timer.postMessage("ping");

    eventsToListenFor.forEach(event =>
      document.addEventListener(event, resetTimer)
    );

    timer.onmessage = logout;

    return () => {
      timer.terminate();
      eventsToListenFor.forEach(event =>
        document.removeEventListener(event, resetTimer)
      );
    };
  }, [user]);

  useEffect(() => {
    (window as any).logout = () => firebase.auth().signOut();
  }, []);

  if (hasAuthError) return <h1>Oops! Encountered an authentication failure</h1>;
  if (isAuthenticating) return <PageSpinner>Authenticating...</PageSpinner>;

  if (!user) {
    return (
      <div className="SocialButtonsWrapper">
        <img src="/logo.svg" alt="SimplySNAP" />
        <div className="SocialButtons">
          <SocialButton provider="google" onClick={loginWithGoogle}>
            Continue with Google
          </SocialButton>
          <SocialButton provider="synapse" onClick={loginWithMicrosoft}>
            Continue with Synapse
          </SocialButton>
        </div>
      </div>
    );
  }

  return (
    <UserContext.Provider value={user}>{props.children}</UserContext.Provider>
  );
}
