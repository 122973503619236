import React from "react";
import "./Spinner.scss";

export default function Spinner({ size = 1 }: { size?: number }) {
  return (
    <svg
      className="Spinner"
      width={`${32 * size}px`}
      height={`${32 * size}px`}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="SpinnerPath"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      />
    </svg>
  );
}
