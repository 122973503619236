import React, { createContext, useContext } from "react";
import { Switch, Route, match } from "react-router-dom";
import { SiteModel, SiteState } from "api/models";
import { PageSpinner } from "./common";
import FixturesPanel from "./Fixtures/FixturesPanel";
import CreateFixturePanel from "./CreateFixture/CreateFixturePanel";
import FixtureResults from "./FixtureResults/FixtureResults";
import SiteResults from "./Sites/SiteResults";
import { useSite } from "./firestore";

const SiteContext = createContext<SiteModel>(null as any);

export function useCurrentSite(): SiteModel {
  return useContext(SiteContext);
}

export default function SiteGuard({
  match
}: {
  match: match<{ siteId: string }>;
}) {
  const [site, hasSiteError] = useSite(match.params.siteId);

  if (hasSiteError) return <h1>Could not load a site</h1>;
  if (!site) return <PageSpinner>Loading site...</PageSpinner>;

  if (site.state !== SiteState.Provisioned) {
    return <SiteResults site={site} />;
  }

  return (
    <SiteContext.Provider value={site}>
      <Switch>
        <Route
          path={`${match.url}/create-fixture`}
          component={CreateFixturePanel}
        />
        <Route
          path={`${match.url}/results/:fixtureId`}
          component={FixtureResults}
        />
        <Route component={FixturesPanel} />
      </Switch>
    </SiteContext.Provider>
  );
}
