import React from "react";
import "./Icon.scss";

export default function Icon(props: { children: string; size?: number }) {
  return (
    <i
      className="material-icons"
      style={{
        fontSize: (props.size || 1) * 24
      }}
    >
      {props.children}
    </i>
  );
}
