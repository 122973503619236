import React from "react";
import { ActionCard } from "ui/common";

export default function EmptyState(props: { onAddFixture: () => void }) {
  return (
    <ActionCard
      title="Test Your Lighting Installation"
      action="Add Lighting Fixture"
      onAction={props.onAddFixture}
    >
      This tool will automatically test the lighting fixtures you have
      installed. To start testing, scan the QR codes that came with your
      lighting fixtures and assign them to the pole you installed the lighting
      fixture on.
    </ActionCard>
  );
}
