import React from "react";
import { FixtureModel, FixtureTestState } from "api/models";
import { Icon, Spinner } from "ui/common";
import "./FixturePreview.scss";

function FixtureStatePreview({
  fixture
}: {
  fixture: FixtureModel;
}): React.ReactElement {
  switch (fixture.state) {
    case FixtureTestState.Success:
      return (
        <div className="Success FixtureStateIcon">
          <Icon>check</Icon>
        </div>
      );

    case FixtureTestState.TestingAtZero:
    case FixtureTestState.TestingAtFifty:
    case FixtureTestState.TestingAtOneHundred:
    case FixtureTestState.CheckingPowerLevels:
      return <Spinner />;

    case FixtureTestState.TestingAtZeroFailed:
    case FixtureTestState.TestingAtFiftyFailed:
    case FixtureTestState.TestingAtOneHundredFailed:
    case FixtureTestState.PowerTestFailed:
      return (
        <div className="Failure FixtureStateIcon">
          <Icon>close</Icon>
        </div>
      );

    default:
      return <></>;
  }
}

export default function({
  fixture,
  onSelect
}: {
  fixture: FixtureModel;
  onSelect: (fixture: FixtureModel) => void;
}) {
  return (
    <button className="FixturePreview" onClick={() => onSelect(fixture)}>
      <div className="leftIcon">
        <Icon size={1.2}>lightbulb_outline</Icon>
      </div>
      <h3>Pole {fixture.pole}</h3>
      <div className="details">
        <span>{fixture.snapaddr}</span>
      </div>
      <div className="FixtureState">
        <FixtureStatePreview fixture={fixture} />
      </div>
    </button>
  );
}
