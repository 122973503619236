import React from "react";
import { SiteModel } from "api/models";
import SiteCard from "./SiteCard";
import "./SiteCardList.scss";

export default ({ sites }: { sites: SiteModel[] }) => {
  return (
    <div className="SiteCardList">
      {sites.map(site => (
        <SiteCard key={site.id} site={site} />
      ))}
    </div>
  );
};
