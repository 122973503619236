import { useState, useEffect } from "react";
import { SiteModel, FixtureModel } from "api/models";
import firebase from "firebase/app";
import "firebase/firestore";
import { useIsAdmin } from "./AdminGuard";
import { useUser } from "./AuthGuard";

export function useFixtures(site: SiteModel) {
  const [fixtures, setFixtures] = useState(null as null | FixtureModel[]);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(`/sites/${site.id}/fixtures`)
      .onSnapshot(snap => {
        const fixtures = snap.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as any;

        setFixtures(fixtures);
      });
  }, [site]);

  return fixtures;
}

export function useSites() {
  const user = useUser();
  const isAdmin = useIsAdmin();
  const [sites, setSites] = useState([] as SiteModel[]);

  useEffect(() => {
    const collection = firebase.firestore().collection(`/sites`);
    const query = isAdmin
      ? collection
      : collection.where("userIds", "array-contains", user.uid);

    return query.onSnapshot(snap => {
      const sites = snap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as any;

      setSites(sites);
    });
  }, [user, isAdmin]);

  return sites;
}

export function useSite(siteId: string) {
  const [site, setSite] = useState(null as null | SiteModel);
  const [error, setError] = useState(null as any);

  useEffect(() => {
    return firebase
      .firestore()
      .doc(`/sites/${siteId}`)
      .onSnapshot(
        snap => {
          const site = {
            id: snap.id,
            ...snap.data()
          };

          setSite(site as any);
        },
        error => setError(error)
      );
  }, [siteId]);

  return [site, error];
}
