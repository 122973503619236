import React from "react";
import { useSites } from "ui/firestore";
import { TitleBar, Fab, ContentArea } from "ui/common";
import { useRouter } from "ui/router";
import SiteCardList from "./SiteCardList";
import AdminGuard from "ui/AdminGuard";

export default function() {
  const sites = useSites();
  const router = useRouter();

  const onAddSite = () => router.history.push("/sites/add");

  return (
    <>
      <TitleBar>
        <img src="/s-mark.svg" className="Logo" alt="SimplySNAP" />
        Sites
      </TitleBar>
      <ContentArea>
        {hasScrolled => (
          <>
            <SiteCardList sites={sites} />
            <AdminGuard>
              <Fab onClick={onAddSite} icon="add" minimal={hasScrolled}>
                Add Site
              </Fab>
            </AdminGuard>
          </>
        )}
      </ContentArea>
    </>
  );
}
