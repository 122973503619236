import React, { useState } from "react";
import { startTestingSite } from "ui/functions";
import { useCurrentSite } from "ui/SiteGuard";
import { ActionCard } from "ui/common";

export default function TestCard() {
  const site = useCurrentSite();
  const [pending, setPending] = useState(false);

  async function startTest() {
    setPending(true);

    startTestingSite({ siteId: site.id })
      .then(() => setPending(false))
      .catch(() => {
        setPending(false);

        alert("Cannot communicate with the site controller");
      });
  }

  return (
    <ActionCard
      title="Test the Site"
      action="Start Test"
      disabled={pending}
      onAction={startTest}
    >
      The test will verify that the fixtures are working correctly. Ensure the
      gateway and fixtures are powered on.
    </ActionCard>
  );
}
