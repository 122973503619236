import { useState, useMemo, useCallback } from "react";

export type Validator<T> = (value: T) => string | true;

/**
 * Approximate implementation of FormControl from
 * @angular/forms using hooks.
 */
export interface FormControl<T> {
  value: T;
  touched: boolean;
  valid: boolean;
  errors: string[];
  showErrors: boolean;
  writeValue: (value: T) => void;
  touch: () => void;
  reset: () => void;
}

export function useFormControl<T>(
  initialValue: T,
  validators: Validator<T>[] = []
): FormControl<T> {
  const [value, writeValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);

  const errors = useMemo(() => {
    return validators.reduce(
      (messages, validator) => {
        const validOrMessage = validator(value);

        if (validOrMessage === true) return messages;

        messages.push(validOrMessage);

        return messages;
      },
      [] as string[]
    );
  }, [validators, value]);

  const valid = useMemo(() => {
    return errors.length === 0;
  }, [errors]);

  const showErrors = useMemo(() => {
    return !valid && touched;
  }, [valid, touched]);

  return {
    value,
    touched,
    valid,
    errors,
    writeValue,
    showErrors,
    touch: () => setTouched(true),
    reset: () => {
      writeValue(initialValue);
      setTouched(false);
    }
  };
}

/**
 * Approximate implementation of FormGroup from
 * @angular/forms using hooks.
 */
export interface FormGroup<V> {
  value: V;
  touched: boolean;
  valid: boolean;
  errors: string[];
  controls: FormControlMap<V>;
  patchValue(value: Partial<V>): void;
  touch: () => void;
  reset: () => void;
}

type FormControlMap<V> = {
  [P in keyof V]: FormControl<V[P]>;
};

export function useFormGroup<V>(
  controlsMap: FormControlMap<V>,
  validators: Validator<V>[] = []
): FormGroup<V> {
  const controlNames: (keyof V)[] = useMemo(
    () => Object.keys(controlsMap) as any,
    [controlsMap]
  );
  const controls = useMemo(
    () => controlNames.map(controlName => controlsMap[controlName]),
    [controlsMap, controlNames]
  );
  const values = useMemo(() => controls.map(control => control.value), [
    controls
  ]);
  const valids = useMemo(() => controls.map(control => control.valid), [
    controls
  ]);
  const toucheds = useMemo(() => controls.map(control => control.touched), [
    controls
  ]);
  const touches = useMemo(() => controls.map(control => control.touch), [
    controls
  ]);
  const resets = useMemo(() => controls.map(control => control.reset), [
    controls
  ]);
  const value: V = useMemo(() => {
    return controlNames.reduce(
      (value, controlName, index) => {
        (value as any)[controlName] = values[index];

        return value;
      },
      {} as V
    );
  }, [controlNames, values]);
  const errors = useMemo(() => {
    return validators.reduce(
      (messages, validator) => {
        const validOrMessage = validator(value);

        if (validOrMessage === true) return messages;

        messages.push(validOrMessage);

        return messages;
      },
      [] as string[]
    );
  }, [validators, value]);
  const valid = useMemo(() => {
    return errors.length === 0 && valids.every(valid => valid);
  }, [valids, errors]);
  const touched = useMemo(() => {
    return toucheds.some(touched => touched);
  }, [toucheds]);
  const touch = useCallback(() => {
    touches.forEach(touch => touch());
  }, [touches]);
  const reset = useCallback(() => {
    resets.forEach(reset => reset());
  }, [resets]);
  const patchValue = useCallback(
    (value: Partial<V>) => {
      controlNames.forEach(controlName => {
        if (controlName in value) {
          controlsMap[controlName].writeValue((value as any)[controlName]);
        }
      });
    },
    [controlNames, controlsMap]
  );

  return {
    controls: controlsMap,
    errors,
    value,
    valid,
    touched,
    touch,
    reset,
    patchValue
  };
}
