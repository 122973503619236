import React, { useState } from "react";
import { scheduleCensusForSite } from "ui/functions";
import { ActionCard } from "ui/common";
import { useCurrentSite } from "../SiteGuard";

export default function ScheduleCensus() {
  const site = useCurrentSite();
  const [pending, setPending] = useState(false);

  async function scheduleCensus() {
    setPending(true);
    
    scheduleCensusForSite({ siteId: site.id })
      .catch(() => {
        setPending(false);
        alert("Cannot communicate with the site controller")
      })
  }
  
  return (
    <ActionCard
      title="Schedule Overnight Census"
      action="Schedule Census"
      disabled={pending}
      onAction={scheduleCensus}
    >
      A Census can be scheduled to run tonight after peak hours but before overnight power changes.  This will allow the Site Controller to discover controllers in its network.
    </ActionCard>
  );
}
