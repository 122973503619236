import React from "react";
import Icon from "ui/common/Icon";
import "./Fab.scss";

export default function Fab(
  props: { icon: string; minimal?: Boolean } & React.ButtonHTMLAttributes<
    HTMLButtonElement
  >
) {
  const { icon, minimal, children, ...buttonProps } = props;
  return (
    <div className={`Fab ${minimal ? "FabMinimal" : ""}`}>
      <button {...buttonProps}>
        <Icon>{icon}</Icon>
        <span className="FabContent">{children}</span>
      </button>
    </div>
  );
}
