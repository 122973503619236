import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import SiteGuard from "./SiteGuard";
import { useIsAdmin } from "./AdminGuard";
import SitesPage from "./Sites/SitesPage";
import CreateSitePage from "./CreateSite/CreateSitePage";
import "./App.scss";

const Routes = () => {
  const isAdmin = useIsAdmin();

  return (
    <Switch>
      <Route path="/" exact component={SitesPage} />
      {isAdmin && <Route path="/sites/add" component={CreateSitePage} />}
      <Route path="/:siteId" component={SiteGuard} />
    </Switch>
  );
};

const App: React.FC = () => {
  return (
    <AuthGuard>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthGuard>
  );
};

export default App;
