import React from "react";
import { RouteComponentProps } from "react-router";
import { TitleBar, ContentArea } from "ui/common";
import { useCurrentSite } from "ui/SiteGuard";
import FixtureForm from "./FixtureForm";
import "./CreateFixturePanel.scss";

export default function CreateFixturePanel({ history }: RouteComponentProps) {
  const site = useCurrentSite();

  const onClose = () => history.push(`/${site.id}`);

  return (
    <div className="CreateFixturePanel">
      <TitleBar canLeave onLeave={onClose}>
        Add Fixture
      </TitleBar>
      <ContentArea>
        {() => <FixtureForm onCreate={() => onClose()} />}
      </ContentArea>
    </div>
  );
}
