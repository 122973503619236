import React, { useContext, createContext, useReducer, useMemo } from "react";
import "./Stepper.scss";

type StepperAction =
  | { type: "goto"; step: number }
  | { type: "goBack" }
  | { type: "continue" };

interface StepperState {
  activeStep: number;
  dispatch(action: StepperAction): void;
}

const StepperContext = createContext<StepperState>({
  activeStep: -1,
  dispatch: () => {}
});

export function Stepper(props: { children: any }) {
  const [state, dispatch] = useReducer(
    (state: { activeStep: number }, action: StepperAction) => {
      switch (action.type) {
        case "goto": {
          return { activeStep: action.step };
        }

        case "goBack": {
          return { activeStep: state.activeStep - 1 };
        }

        case "continue": {
          return { activeStep: state.activeStep + 1 };
        }
      }
    },
    { activeStep: 1 }
  );

  const ctx: StepperState = useMemo(() => {
    return { ...state, dispatch };
  }, [state, dispatch]);

  return (
    <StepperContext.Provider value={ctx}>
      <div className="Stepper">{props.children}</div>
    </StepperContext.Provider>
  );
}

export function Step(props: {
  step: number;
  title: string;
  children: (
    isActive: boolean,
    isVisited: boolean,
    step: { goBack: () => void; continue: () => void }
  ) => any;
}) {
  const stepper = useContext(StepperContext);

  const step = useMemo(() => {
    return {
      goBack: () => stepper.dispatch({ type: "goBack" }),
      continue: () => stepper.dispatch({ type: "continue" })
    };
  }, [stepper]);

  const isActive = useMemo(() => {
    return props.step === stepper.activeStep;
  }, [stepper, props.step]);

  const isVisited = useMemo(() => {
    return props.step < stepper.activeStep;
  }, [stepper, props.step]);

  return (
    <div
      className={`StepperStep ${isActive && "Active"} ${isVisited &&
        "Visited"}`}
      data-step={`${props.step}`}
    >
      <span className="StepperStepTitle">{props.title}</span>
      <div className="StepperStepContent">
        {props.children(isActive, isVisited, step)}
      </div>
    </div>
  );
}
